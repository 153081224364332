import anime from 'animejs'
import each from 'lodash/each'
import promise from 'helpers/promise'

const duration = 400
const easing = 'easeInQuad'

export default function (callback) {
  this.header.hide()

  const cta = this.el.querySelector('.scroll-cta')
  const description = this.el.querySelector('.page__description-text')
  const showcase = this.el.querySelector('.showcase')
  const talents = this.el.querySelector('.talents')
  const panel = this.el.querySelector('.panel')
  const timeline = anime.timeline()

  const base = {
    opacity: {
      value: 0,
      easing: 'linear',
      duration: 250
      // delay: 100
    },
    translateX: el => [0, 250],
    easing,
    duration
  }

  const panelBase = {
    translateX: [0, '100%'],
    duration,
    easing
  }

  const targets = [showcase, talents, [description, cta], false, false, panel]
  const delay = i => i * 100

  each(targets, (target, i) => {
    if (!target) return
    timeline.add(
      Object.assign({}, target === panel ? panelBase : base, {
        targets: target
      }),
      delay(i)
    )
  })

  panel.style.display = 'block'
  panel.classList.add('white')

  timeline.finished
    .then(() => (document.body.className = 'white no-transition'))
    .then(() => promise.wait(50))
    .then(callback)
}
