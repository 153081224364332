import Talent from '../talent/Talent'
import EcuriePage from 'navigation/EcuriePage'

import { animateIn, animateOut } from 'core/animations'

import each from 'lodash/each'

class Project extends Talent {
  noGrid = true

  prepare () {
    super.prepare()
    const blocks = this.el.querySelectorAll('.project__block')
    const standalone =
      this.players.length + this.galleries.length === 1 && blocks.length === 1

    if (standalone) {
      if (this.players.length) this.standaloneItem = this.players[0]
      if (this.galleries.length) this.standaloneItem = this.galleries[0]
      this.standaloneItem && this.standaloneItem.standalone()
    }
  }

  show (callback) {
    if (this.standaloneItem) {
      const inner = this.inner
      if (this.standaloneItem.video) this.inner = this.standaloneItem.el

      this.fadeIn().then(() => {
        this.standaloneItem.show().then(callback)
        this.inner = inner
        this.resetAlpha()
      })
    } else {
      this._show(...arguments)
    }
  }

  _show (callback) {
    if (this.urlBlock) {
      if (this.urlBlock.video) {
        const inner = this.inner
        this.inner = this.urlBlock.full || this.urlBlock.el

        this.fadeIn()
        this.urlBlock.show().then(() => {
          this.inner = inner
          this.header.show()
          this.resetAlpha()
          callback()
        })
      } else {
        super.show(...arguments)
      }
      this.urlBlock = null
      return
    }

    this.resetAlpha()
    this.header.show()

    const description = this.el.querySelector('.project__detail')
    const blocks = this.el.querySelectorAll('.project__block')

    animateIn({
      targets: [description, blocks],
      delay: 200,
      subDelay: 100,
      mainDelay: 200
    })
      .then(() => each(blocks, el => (el.style.transform = '')))
      .then(() => (description.style.transform = ''))
      .then(() => this.resize())
      .then(callback)
  }
  _hide (callback) {
    this.header.hide()

    const description = this.el.querySelector('.project__detail')
    const blocks = this.el.querySelectorAll('.project__block')

    animateOut({
      targets: [description, blocks],
      delay: 200,
      subDelay: 100
    }).then(callback)
  }

  hide (callback) {
    if (this.standaloneItem) {
      const inner = this.inner
      if (this.standaloneItem.video) {
        this.inner.style.opacity = 0
        this.inner = this.standaloneItem.el
      }
      this.standaloneItem.hide()
      EcuriePage.prototype.hide.call(this, () => {
        this.inner = inner
        callback()
      })
    } else {
      this._hide(...arguments)
    }
  }
}

Project.pageName = 'Project'

export default Project
