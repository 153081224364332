import Page from './Page'
import anime from 'animejs'

import promise from 'helpers/promise'
import Reveal from 'modules/reveal/Reveal'

import map from 'lodash/map'
import each from 'lodash/each'
import Header from 'modules/header/Header'

export default class EcuriePage extends Page {
  imagesToLoad = []

  constructor (el, options = {}) {
    super(...arguments)
    this.inner = this.el.querySelector('.page__inner')
    const headerDiv = this.el.querySelector('.header')
    if (headerDiv) this.header = new Header(headerDiv)
  }

  fadeOut (direct) {
    return anime({
      targets: this.inner,
      opacity: [1, 0],
      easing: 'linear',
      duration: direct ? 1 : 400
    }).finished
  }

  fadeIn (direct = false) {
    return anime({
      targets: this.inner,
      opacity: [0, 1],
      easing: 'linear',
      duration: direct ? 1 : 500
    }).finished
  }

  prepare (previousPage) {
    this.inner.style.opacity = 0
  }

  resetAlpha () {
    this.inner.style.opacity = 1
  }

  show (callback, previousPage, no) {
    this.header && this.header.show()
    return this.fadeIn().then(() => {
      callback && callback()
      document.body.style.cursor = 'inherit'
      this.resize()
    })
  }

  askShow = previousPage => new Promise((resolve, reject) => {
    this.preloadImages()
      .then(() => this.onImageLoad())
    // .then(() => promise.wait(1000))
      .then(() => promise.wait(previousPage && !this.imagesToLoad ? 0 : 200))
      .then(() => {
        this.show(() => {
          this.state.shown = true
          resolve()
        }, previousPage)
      })
  })

  preloadImages () {
    if (!this.imagesToLoad || !this.imagesToLoad.length)
      return Promise.resolve()
    document.body.style.cursor = 'wait'
    return Promise.all(map(this.imagesToLoad, this.loadImage))
  }

  loadImage = img => new Promise(resolve => {
    if (img.naturalWidth !== 0) return resolve()
    img.onload = () => resolve()
  })

  onImageLoad (callback, previousPage) {
    this.reveals = map(
      this.el.querySelectorAll('.reveal-block'),
      el => new Reveal(el)
    )
  }

  hide (callback, nextPage) {
    this.header && this.header.hide()
    return this.fadeOut().then(() => callback && callback())
  }

  hideWithItem (item, callback, nextPage) {
    const bounds = item.getBoundingClientRect()
    const clone = item.cloneNode(true)

    item.style.position = 'fixed'
    item.style.zIndex = 200
    item.style.margin = 0
    item.style.top = bounds.top + 'px'
    item.style.left = bounds.left + 'px'
    item.style.width = bounds.width + 'px'
    item.style.height = bounds.height + 'px'

    clone.style.height = bounds.height + 'px'

    item.parentNode.insertBefore(clone, item)
    this.el.appendChild(item)

    item.classList.add('hover')

    Promise.resolve()
      .then(() => promise.wait(10))
      .then(() => item.classList.add('hide-with-item'))
      .then(() => this.fadeOut())
      .then(() => promise.wait(500))
      .then(() => {
        anime({
          targets: item,
          opacity: {
            value: [1, 0],
            delay: 200,
            duration: 400,
            easing: 'linear'
          },
          translateY: [0, -40],
          duration: 600,
          easing: 'easeInOutSine',
          complete: callback
        })
      })
  }

  flush () {
    const buttons = this.el.querySelectorAll('.menu-button')
    each(buttons, b => b.removeEventListener('click', this.onMenuClick))
    this.reveals && each(this.reveals, revealBlock => revealBlock.flush())
    this.header && this.header.flush()
    super.flush()
  }

  resize () {
    this.reveals && each(this.reveals, revealBlock => revealBlock.resize())
    this.header && this.header.resize()
    super.resize()
  }
}
