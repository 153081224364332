import scroll from 'navigation/scroll'
import each from 'lodash/each'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import math from 'helpers/math'
import resize from 'helpers/resize'
import browser from 'helpers/browser'

import anime from 'animejs'
import BezierEasing from 'bezier-easing'

const easing = BezierEasing(...anime.penner.easeOutSine)

class EcurieGrid {
  constructor (el, {
    rightPanelClass = '.projects',
    contentClass = '.page__content',
    leftPanelClass = '.page__description',
    projectsClass = '.project',
    scrollCtaClass = '.scroll-cta',
    autoplay = true,
    clickable = true,
    transform = true,
    talent = false,
    resetVideo = true,
    updateCallback = null
  } = {}) {
    this.el = el
    scroll.instance().on(this.scroll)

    this.rightPanel = el.querySelector(rightPanelClass)
    this.content = el.querySelector(contentClass)
    this.leftPanel = el.querySelector(leftPanelClass)
    this.projects = el.querySelectorAll(projectsClass)

    this.cta = el.querySelector(scrollCtaClass)
    this.cta && this.cta.addEventListener('click', this.scrollClicked)

    this.talent = talent
    this.resetVideo = resetVideo
    this.transform = transform
    this.updateCallback = updateCallback

    if (autoplay) {
      each(this.projects, project => {
        clickable && project.addEventListener('click', this.click)
        project.addEventListener('mouseover', this.mouseover)
        project.addEventListener('mouseout', this.mouseout)
      })
    }
  }

  prepare () {
    this.rightPanel.style.opacity = 0
  }

  show () {
    this.rightPanel.style.opacity = 'inherit'
  }

  start () {
    this.started = true
    this.scroll()
  }

  expanded = false

  scrollClicked = () => {
    scroll.scrollTo(this.maxOffset, 600)
  }

  scroll = () => {
    if (this.frozen) return
    const scrollTop = scroll.scrollTop()
    const expanded = scrollTop > this.maxOffset / 2

    if (expanded !== this.expanded) {
      this.el.classList.toggle('expanded', expanded)
      this.expanded = expanded
    }

    this.updateTransform(scrollTop)
  }

  maxOffset = 400

  updateTransform = scrollTop => {
    if (!this.columns) return

    const itemCount = 5
    const maxOffset = this.maxOffset
    const itemOffset = 20
    const offset = easing(math.mapClamp(scrollTop, 0, maxOffset))

    const yOffset = 100

    if (this.columns.length < 2) return

    if (offset !== this.transformOffset) {
      this.transformOffset = offset

      const xTransition = -offset * this.rightPanelOffset
      const yTransition = -offset * yOffset

      this.leftPanel.style.opacity = 1 - offset
      this.leftPanel.style.transform = `translate(${xTransition}px, ${yTransition}vh)`

      if (this.transform) {
        this.rightPanel.style.transform = `translateX(${xTransition}px)`
      } else {
        // this.rightPanel.style.top = `${yTransition}vh`
        this.rightPanel.style.setProperty('--offset-x', 1 - offset)
        // this.leftPanel.style.top = `${yTransition}vh`
        // this.leftPanel.style.marginLeft = `${xTransition}px`
      }

      // this.columns[0].style.transform = `translateY(${yTransition}vh)`
      // this.columns[0].style.opacity = offset
    }

    const isItemsStillAnimating =
      scrollTop >= 0 && scrollTop <= maxOffset + itemOffset * itemCount

    if (isItemsStillAnimating) {
      const firstItems = this.firstColumn.slice(0, itemCount)
      each(firstItems, (item, i) => {
        const offset = easing(
          math.mapClamp(scrollTop, itemOffset * i, maxOffset + itemOffset * i)
        )
        const yTransition = (1 - offset) * 50
        item.style.opacity = offset

        if (this.transform)
          item.style.transform = `translateY(${yTransition}vh)`
        else
          item.style.top = `${yTransition}vh`
      })
    }

    this.updateCallback && this.updateCallback()
  }

  resetTransform = () => {
    this.rightPanel.setAttribute('style', '')
    this.leftPanel.setAttribute('style', '')
    each(this.projects, (item, i) => {
      item.setAttribute('style', '')
    })
  }

  click = event => {
    this.lastProjectClicked = event.currentTarget
  }

  mouseover = event => {
    const target = event.currentTarget
    const video = target.querySelector('video')
    const gallery = target.querySelector('.project__gallery, .gallery-preview-inner')

    if (video) {
      browser.prepareVideo(video)
      if (this.resetVideo) video.currentTime = 0
      video.onplay = () => target.firstElementChild.classList.add('playing')
      video.play()
    } else if (gallery) {
      this.galleryOffset = this.talent ? 0 : 1
      const children = gallery.children.length
      this.galleryTimer && clearInterval(this.galleryTimer)
      this.galleryTimer = setInterval(
        () => this.tickGallery(gallery, children),
        300
      )
    }
  }

  mouseout = event => {
    const target = event.currentTarget
    const video = target.querySelector('video')
    const gallery = target.querySelector('.project__gallery')

    this.galleryTimer && clearInterval(this.galleryTimer)

    if (video) {
      target.firstElementChild.classList.remove('playing')
      video.pause()
    } else if (gallery) {
      gallery.style.transform = 'translateX(-100%)'
    }
  }

  tickGallery = (gallery, children) => {
    if (this.talent) {
      gallery.style.transform = `translateX(${(this.galleryOffset) * -101}%)`
      this.galleryOffset++
      if (this.galleryOffset >= children - 1) this.galleryOffset = 0
    } else {
      gallery.style.transform = `translateX(${(this.galleryOffset - 1) * -100}%)`
      this.galleryOffset++
      if (this.galleryOffset > children) this.galleryOffset = 0
    }
  }

  columnNumber = () => {
    if (!resize.mq.tablet()) return 3
    if (!resize.mq.tabletPortrait()) return 2
    return 1
  }

  resize () {
    if (!this.projects[0]) return

    this.rightPanelOffset = -parseInt(
      window.getComputedStyle(this.rightPanel).getPropertyValue('right')
    )

    if (isNaN(this.rightPanelOffset)) {
      this.rightPanelOffset =
        this.rightPanel.offsetLeft - this.leftPanel.offsetLeft
    }

    const columnNumber = this.columnNumber()
    if (this.columns && this.columns.length === columnNumber) return

    // Compute columns
    const projectsContainer = this.rightPanel

    this.columns = []
    // this.columnsLength = []
    this.firstColumn = []

    for (let i = 0; i < columnNumber; i++) {
      // this.columnsLength[i] = 0
      this.columns[i] = document.createElement('ul')
      this.columns[i].className = `column column-${i}`
    }

    const items = this.reorder(this.projects, columnNumber)
    each(items, (project, i) => {
      const column = items.length >= 8 || columnNumber === 1 ? (i % columnNumber) : (1 + (i % (columnNumber - 1)))
      // const height = project.offsetHeight

      // if (columnNumber !== 1 && i > 6 && height !== 0) {
      //   column = i % columnNumber
      // } else {
      //   column = i % columnNumber
      // }

      // this.columnsLength[column] += project.offsetHeight
      this.columns[column].appendChild(project)

      if (column === 0) this.firstColumn.push(project)
      else project.setAttribute('style', '')
    })

    projectsContainer.innerHTML = ''
    each(this.columns, column => projectsContainer.appendChild(column))
    this.scroll()

    if (columnNumber === 1) this.resetTransform()
    this.show()
  }

  reorder = (items, columnNumber) => {
    const newItems = Array.from(items).slice(0)
    const move = (from, to) => newItems.splice(to, 0, newItems.splice(from, 1)[0])
    if (columnNumber === 3) {
      move(6, 0)
      move(7, 3)
      move(8, 6)
    } else if (columnNumber === 2) {
      move(2, 0)
      move(3, 2)
    }

    return compact(newItems)
  }

  flush () {
    scroll.instance().off(this.scroll)
    this.cta && this.cta.removeEventListener('click', this.scrollClicked)

    this.projects && each(this.projects, project => {
      project.addEventListener('click', this.click)
      project.removeEventListener('mouseover', this.mouseover)
      project.removeEventListener('mouseout', this.mouseout)
    })
  }
}

export default EcurieGrid
