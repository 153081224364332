import anime from 'animejs'
import each from 'lodash/each'
import assign from 'lodash/assign'
import pick from 'lodash/pick'
import omit from 'lodash/omit'

const defaulParams = {
  delay: 100,
  subDelay: 0,
  mainDelay: 0,
  duration: 450,
  opacityDuration: 300
}

const notAnimParams = ['targets', 'delay', 'subDelay', 'mainDelay']

const animateIn = (options) => {
  options = assign({}, defaulParams, {
    translateY: 50,
    easing: 'easeOutSine'
  }, options)

  return animate(
    pick(options, notAnimParams),
    Object.assign(omit(options, notAnimParams), {
      opacity: {
        value: [0, 1],
        easing: 'linear',
        duration: options.opacityDuration
      },
      translateY: options.translateY === false ? options.translateY : [options.translateY, 0]
    }))
}

const animateOut = (options) => {
  options = assign({}, defaulParams, {
    translateY: -50,
    easing: 'easeInSine'
  }, options)

  return animate(
    pick(options, notAnimParams),
    Object.assign(omit(options, notAnimParams), {
      opacity: {
        value: 0,
        easing: 'linear',
        duration: options.opacityDuration
      },
      translateY: options.translateY === false ? options.translateY : [0, options.translateY]
    }))
}

const animate = (options, animOptions) => {

  delete animOptions.opacityDuration

  const baseOptions = Object.assign({
    delay: (el, i) => options.subDelay * i
  }, animOptions)

  const timeline = anime.timeline()

  each(options.targets, (target, i) => {
    if (!target) return
    return timeline.add(Object.assign({
      targets: target
    }, baseOptions), options.delay * i + options.mainDelay)
  })

  return timeline.finished
}

export {
  animateIn,
  animateOut
}
