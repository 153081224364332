import anime from 'animejs'
import each from 'lodash/each'
import promise from 'helpers/promise'

const duration = 400
const easing = 'easeInQuad'

export default function (callback) {
  this.header.hide()

  const description = this.el.querySelector('.page__description-text')
  const cta = this.el.querySelector('.scroll-cta')
  const panel = this.el.querySelector('.panel')
  const timeline = anime.timeline()

  const base = {
    opacity: {
      value: 0,
      easing: 'linear',
      duration: 250
    },
    translateX: [0, -250],
    easing,
    duration
  }

  const panelBase = {
    translateX: [0, '-100%'],
    duration,
    easing
  }

  const targets = [[description, cta, this.grid.columns[0]]]
  each(this.grid.columns.slice(1, 5), column => targets.push(column))
  targets.push(false, false, panel)

  const delay = i => i * 100

  each(targets, (target, i) => {
    if (!target) return
    timeline.add(Object.assign({}, target === panel ? panelBase : base, {
      targets: target
    }), delay(i))
  })

  panel.style.display = 'block'

  timeline.finished
    .then(() => (document.body.className = 'black no-transition'))
    .then(() => promise.wait(50))
    .then(callback)
}
