import anime from 'animejs'
import each from 'lodash/each'

const duration = 400
const easing = 'easeOutQuad'
const mainDelay = 50

export default function (callback) {
  this.resetAlpha()
  this.header.show()

  const cta = this.el.querySelector('.scroll-cta')
  const description = this.el.querySelector('.page__description-text')
  const talents = this.el.querySelector('.talents')
  const showcase = this.el.querySelector('.showcase')
  const timeline = anime.timeline()

  const base = {
    opacity: {
      value: [0, 1],
      easing: 'linear',
      duration: 350,
      delay: 0
    },
    translateX: [250, 0],
    easing,
    duration
  }

  const targets = [[description, cta], talents, showcase]
  const delay = i => i * 100 + mainDelay

  each(targets, (target, i) => {
    timeline.add(
      Object.assign({}, base, {
        targets: target
      }),
      delay(i)
    )
  })

  timeline.finished.then(callback)
}
