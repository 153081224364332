import resize from 'helpers/resize'
import browser from 'helpers/browser'
import scroll from 'navigation/scroll'

import raf from '@internet/raf'

class ProjectScroller {
  constructor (el) {
    this.offset = 0
    this.defaultSpeed = this.speed = 0.01
    this.maxSpeed = 0.1

    this.el = el
    this.el.addEventListener('mousemove', this.mousemove)
    this.el.addEventListener('mouseout', this.mouseout)

    raf.add(this.tick)
    scroll.virtual().on(this.scroll)
  }

  scroll = (event) => {
    if (event) this.offset -= event.deltaY / 30
  }

  mouseout = (event) => {
    const target = event.toElement
    if (browser.isParent(target, this.el)) return
    this.speed = this.defaultSpeed
  }

  mousemove = (event) => {
    let ratio = (event.pageY / resize.height())
    ratio = (ratio - 0.5) * 2
    this.speed = this.maxSpeed * ratio
  }

  resize () {
  }

  tick = () => {
    const max = 50

    if (resize.mq.tabletPortrait()) return
    this.offset += this.speed

    if (this.offset < max * 0.5) this.offset = this.offset + max
    if (this.offset > max * 1.5) this.offset = this.offset - max

    this.el.style.transform = `translateY(${-this.offset}%)`
  }

  flush () {
    raf.remove(this.tick)
    this.el.removeEventListener('mousemove', this.mousemove)
    this.el.removeEventListener('mouseout', this.mouseout)
  }
}

export default ProjectScroller
