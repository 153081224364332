import ProjectScroller from './ProjectScroller'
import EcuriePage from 'navigation/EcuriePage'
import defer from 'lodash/defer'
import each from 'lodash/each'

import hide from './animations/cavalerie.hide'
import show from './animations/cavalerie.show'
import showLeft from './animations/cavalerie.show.left'
import hideRight from './animations/cavalerie.hide.right'

class Cavalerie extends EcuriePage {
  prepare () {
    super.prepare()

    this.talents = Array.from(this.el.querySelectorAll('li.talent'))
    each(this.talents, talent => talent.addEventListener('click', this.click))

    this.filters = Array.from(this.el.querySelectorAll('.page__filter a'))
    each(this.filters, filter => filter.addEventListener('click', this.filter))

    this.projetScroller = new ProjectScroller(this.el.querySelector('.showcase ul'))
  }

  filter = (event) => {
    event && event.preventDefault()
    const target = event.currentTarget
    const filterId = target.getAttribute('data-filter')

    each(this.filters, filter => {
      filter.parentNode.classList.toggle('current', filter === target)
    })

    each(this.talents, talent => {
      const disable = filterId !== '' && !~talent.getAttribute('data-filter').indexOf(filterId)
      talent.classList.toggle('disable', disable)
    })
  }

  click = (event) => {
    this.talentClicked = event.currentTarget
  }

  show (callback, previousPage) {
    this.resize()
    if (previousPage === 'Ecurie') showLeft.call(this, ...arguments)
    // else super.show(...arguments)
    else show.call(this, ...arguments)
  }

  hide (callback, nextPage) {
    defer(this._hide.bind(this, ...arguments))
  }

  _hide (callback, nextPage) {
    if (nextPage === 'Talent' && this.talentClicked) this.hideWithItem(this.talentClicked, ...arguments)
    else if (nextPage === 'Ecurie') hideRight.call(this, ...arguments)
    else hide.call(this, ...arguments)
  }

  resize () {
    this.resizeHeader('.talents ul')
    this.projetScroller.resize()
    super.resize()
  }

  resizeHeader (reference) {
    const menu = this.el.querySelector('.menu')
    const referenceEl = this.el.querySelector(reference)
    const filters = this.el.querySelector('.page__filter')

    const offset = referenceEl.getBoundingClientRect().left - menu.getBoundingClientRect().right
    filters.style.marginLeft = `${offset}px`
  }

  flush () {
    each(this.talents, talent => talent.removeEventListener('click', this.click))
    this.talents = null
    this.projetScroller.flush()

    super.flush()
  }
}

Cavalerie.pageName = 'Cavalerie'

export default Cavalerie
