import router from 'navigation/router'

import EcuriePage from 'navigation/EcuriePage'
import { animateIn, animateOut } from 'core/animations'

class About extends EcuriePage {
  prepare () {
    super.prepare()

    this.buttonClose = this.el.querySelector('.close-button')
    this.buttonClose.addEventListener('click', this.close)
  }

  show (callback) {
    this.resetAlpha()

    const brands = this.el.querySelectorAll('.brand')

    animateIn({
      targets: [this.buttonClose, brands],
      subDelay: 100
    }).then(callback)
  }

  hide (callback) {
    const brands = this.el.querySelectorAll('.brand')

    animateOut({
      targets: [this.buttonClose, brands],
      subDelay: 100
    }).then(callback)
  }

  close = (event) => {
    event.preventDefault()
    const last = router.lastPage()
    if (last) router.navigate(last)
    else router.navigate(event.currentTarget.getAttribute('href'))
  }

  flush () {
    super.flush()
    this.buttonClose.removeEventListener('click', this.close)
    this.buttonClose = null
  }
}

About.pageName = 'About'

export default About
