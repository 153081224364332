import EcuriePage from 'navigation/EcuriePage'
import Player from 'modules/project-block/player/Player'
import Gallery from 'modules/project-block/gallery/Gallery'

import { animateIn, animateOut } from 'core/animations'

import map from 'lodash/map'
import each from 'lodash/each'
import find from 'lodash/find'
import anime from 'animejs'

import resize from 'helpers/resize'
import EcurieGrid from '../ecurie/grid/EcurieGrid'
import debounce from 'lodash/debounce'

class Talent extends EcuriePage {
  prepare () {
    super.prepare()

    if (!this.noGrid) {
      this.grid = new EcurieGrid(this.el, {
        rightPanelClass: '.project__blocks',
        leftPanelClass: '.page__description',
        projectsClass: '.project__block',
        // autoplay: false,
        talent: true,
        resetVideo: false,
        transform: false,
        updateCallback: this.onGridUpdate
      })
    }

    this.moreButton = this.el.querySelector('.project__more')
    this.description = this.el.querySelector('.project__description')
    this.moreButton.addEventListener('click', this.expandDetails)

    this.players = map(
      this.el.querySelectorAll('.video-player'),
      el => new Player(el, { talent: true })
    )
    this.galleries = map(
      this.el.querySelectorAll('.gallery'),
      el => new Gallery(el, { talent: true })
    )

    this.blocks = this.players.concat(this.galleries)
    const hash = window.location.hash
    if (hash) this.openBlock(hash)

    this.grid && each(this.blocks, p => {
      p.on('switchFullscreen', this.onEnterFullscreen)
      p.on('exitFullscreen', this.onExitFullscreen)
    })

    this.resize()
  }

  onGridUpdate = debounce(() => {
    each(this.blocks, p => p.resize())
  }, 100)

  onEnterFullscreen = () => {
    this.grid.frozen = true
  }

  onExitFullscreen= () => {
    setTimeout(() => {
      this.grid.frozen = false
    }, 100)
  }

  openBlock (hash) {
    this.urlBlock = find(this.blocks, ['id', hash.replace('#', '')])
    if (this.urlBlock) this.urlBlock.toggleFullscreen(true, true)
    this.urlBlock.show()
  }

  expandDetails = () => {
    this.expanded = !this.expanded

    const height = this.description.firstElementChild.offsetHeight
    this.moreButton.classList.toggle('expanded', this.expanded)

    anime({
      targets: this.description,
      height: this.expanded ? [0, height] : [height, 0],
      duration: 300,
      easing: 'easeOutSine'
    })
  }

  show (callback) {
    if (this.urlBlock) {
      if (this.urlBlock.video) {
        const inner = this.inner
        this.inner = this.urlBlock.full || this.urlBlock.el

        this.fadeIn()
        this.urlBlock.show().then(() => {
          this.inner = inner
          this.header.show()
          this.resetAlpha()
          callback()
        })
      } else {
        super.show(...arguments)
      }
      this.urlBlock = null
      return
    }

    this.resetAlpha()
    this.header.show()

    const slice = this.grid.columns.length === 1 ? 0 : 1
    const columns = this.columns().slice(slice)
    const description = this.el.querySelector('.page__description')
    const targets = [[description]]

    each(columns, column => each(column, item => (item.style.opacity = 0)))
    each(columns, column => targets.push(false, column))

    animateIn({
      targets,
      subDelay: 100,
      mainDelay: 200
    })
      .then(() => each(columns, column => each(column, item => (item.style.transform = ''))))
      .then(() => (description.style.transform = ''))
      .then(() => this.resize())
      .then(() => this.grid.start())
      .then(callback)
  }

  columns = (limit = 5) => map(this.grid.columns, column => Array.from(column.children).slice(0, limit))

  hide (callback) {
    this.header.hide()

    const columns = this.grid.columns
    const description = this.el.querySelector('.page__description')
    const targets = [description]

    each(columns, column => targets.push(column))
    animateOut({
      targets
    }).then(callback)
  }

  resize () {
    each(this.blocks, p => p.resize())
    this.grid && this.grid.resize()
    super.resize()

    if (resize.mq.tabletPortrait()) {
      if (this.expanded)
        this.description.style.height = this.description.firstElementChild.offsetHeight
    } else {
      this.expanded = false
      this.description.style.height = ''
    }
  }

  flush () {
    this.grid && this.grid.flush()

    each(this.blocks, p => {
      p.off()
      p.flush()
    })
    this.blocks = null
    this.players = null
    this.galleries = null

    super.flush()
  }
}

Talent.pageName = 'Talent'

export default Talent
