import EcuriePage from 'navigation/EcuriePage'
import scroll from 'navigation/scroll'
import anime from 'animejs'
import math from 'helpers/math'
import resize from 'helpers/resize'
import router from 'navigation/router'
// import BezierEasing from 'bezier-easing'

// const easing = BezierEasing(...anime.penner.easeInQuad)

class Intro extends EcuriePage {
  constructor () {
    super(...arguments)
    this.resize()
    scroll.virtual().on(this.scroll)
    this.el.addEventListener('click', this.click)
  }

  scrollTop = 0

  scroll = (event) => {
    if (!event || this.hiding) return

    this.scrollTop = Math.max(0, this.scrollTop - event.deltaY)
    const offset = math.mapClamp(this.scrollTop, 0, this.marginBottom)
    if (offset > 0.3) this.click()
    this.timeline && this.timeline.seek(offset * this.timeline.duration)
  }

  resize () {
    this.marginBottom = 200
    // this.marginBottom = resize.height() * (bowser.desktop ? 1.5 : 1.1)
    // this.marginBottom = this.el.offsetHeight - resize.height()
    super.resize()
  }

  click = () => {
    const cavalerie = ~window.location.hostname.indexOf('cavalerie.')
    if (cavalerie) router.navigate('/cavalerie')
    else router.navigate('/lecurie')
  }

  prepare () {
    super.prepare()
  }

  show (callback) {
    this.resetAlpha()

    const lines = this.el.querySelectorAll(resize.mq.tabletPortrait() ? '.text-inner' : '.line')
    const videoContainer = this.el.querySelector('.video-container')
    const video = videoContainer.querySelector('video')
    const cta = this.el.querySelector('.scroll-cta')

    const duration = 850
    const timeline = anime.timeline()
    const easing = 'easeOutQuad'

    const firstDelay = 200

    timeline.add({
      targets: lines,
      opacity: {
        value: [0, 1],
        duration: 200,
        easing: 'linear'
      },
      translateY: [60, 0],
      rotateY: ['-20deg', 0],
      easing,
      duration,
      delay: (el, i) => i * 100
    }, firstDelay)

    timeline.add({
      targets: videoContainer,
      opacity: [0, 1],
      easing: 'linear',
      duration
    }, firstDelay + 100)

    timeline.add({
      targets: cta,
      opacity: {
        value: [0, 1],
        duration: 200,
        easing: 'linear'
      },
      translateY: [40, 0],
      // rotate: ['-90deg', '-90deg'],
      easing,
      duration
    }, firstDelay + 100)

    timeline.pause()

    timeline.finished
      .then(callback)
      .then(() => this.prepareHide())

    // const clone = browser.resetElement(video)
    // browser.prepareVideo(clone)

    video.ontimeupdate = () => {
      if (video.currentTime < 0.1) return
      video.ontimeupdate = null
      timeline.play()
    }

    const play = video.play()
    if (play.catch) play.catch(() => timeline.play())
  }

  prepareHide () {
    const lines = this.el.querySelectorAll(resize.mq.tabletPortrait() ? '.text-inner' : '.line')
    const video = this.el.querySelector('.video-container')
    const cta = this.el.querySelector('.scroll-cta')

    const duration = 500
    const easing = 'easeOutSine'
    this.timeline = anime.timeline({})

    this.timeline.add({
      targets: lines,
      opacity: {
        value: [1, 0],
        duration: 400,
        easing: 'linear'
      },
      translateY: [0, -60],
      rotateY: [0, '20deg'],
      easing,
      duration,
      delay: (el, i) => i * 50
    }, 0)

    this.timeline.add({
      targets: video,
      translateY: [0, -100],
      opacity: [1, 0],
      duration: 600,
      easing
    }, 0)

    this.timeline.add({
      targets: cta,
      opacity: {
        value: [1, 0],
        duration: 400,
        easing: 'linear'
      },
      translateY: [0, -40],
      // rotate: ['-90deg', '-90deg'],
      easing
    }, 0)

    this.timeline.pause()
  }

  hide (callback) {
    if (!this.timeline) return callback()
    this.hiding = true

    this.timeline.play()
    scroll.lock()
    this.timeline.finished
      .then(() => scroll.unlock())
      .then(callback)
  }

  flush () {
    super.flush()
    scroll.virtual().off(this.scroll)
    this.el.removeEventListener('click', this.click)
  }
}

Intro.pageName = 'Intro'

export default Intro
