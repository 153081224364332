import { animateIn } from 'core/animations'

export default function (callback) {
  this.header.show()
  this.resetAlpha()

  const cta = this.el.querySelector('.scroll-cta')
  const description = this.el.querySelector('.page__description-text')
  const showcase = this.el.querySelector('.showcase')
  const talents = this.el.querySelector('.talents')

  animateIn({
    targets: [[description, cta], talents, showcase],
    mainDelay: 200
  }).then(callback)
}
