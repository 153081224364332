import anime from 'animejs'
import defer from 'lodash/defer'
import throttle from 'lodash/throttle'
import ProjectBlock from '../ProjectBlock'

import browser from 'helpers/browser'
import resize from 'helpers/resize'
import { animateIn, animateOut } from 'core/animations'

class Player extends ProjectBlock {
  constructor (el) {
    super(...arguments)

    this.state = Object.assign(this.state, {
      hover: false,
      started: false,
      playing: false
    })

    this.video = el.querySelector('video')
    this.progress = el.querySelector('.progress')
    this.button = el.querySelector('.toggle-button')
    this.timeline = el.querySelector('.timeline')
    this.fullscreenButton = el.querySelector('.fullscreen-button')

    this.injectDetails(el)
  }

  toggleEvents (add = true) {
    super.toggleEvents(...arguments)
    const method = add ? 'addEventListener' : 'removeEventListener'

    this.video[method]('play', this.play)
    this.video[method]('ended', this.ended)
    this.video[method]('pause', this.pause)
    this.video[method]('loadedmetadata', this.metadata)

    this.timeline[method]('mousedown', this.onSeekStart)
    this.fullscreenButton[method]('click', this.toggleFullscreen)
    document.body[method]('mouseup', this.onSeekStop)
  }

  onSeekStart = () => this.setState({ seeking: true })
  onSeekStop = event => {
    if (!this.state.seeking) return
    defer(() => this.setState({ seeking: false }))
    this.seek(event)
  }

  tick () {
    const progress = this.video.currentTime / this.video.duration

    if (progress !== this.progress) {
      this.progres = progress
      this.progress.style.transform = `scaleX(${progress})`
    }

    if (this.inrtia.stopped) return
    const value = this.inrtia.update()
    this.button.style.transform = `translate(${value.x}px, ${value.y}px)`
  }

  click (event) {
    super.click(...arguments)

    if (this.talent && !this.state.fullscreen) {
      this.video.muted = false
      return this.toggleFullscreen(true)
    }

    if (this.state.seeking) return
    if (this.video.paused) this.video.play()
    else this.video.pause()
  }

  mouseout () {
    super.mouseout(...arguments)
    this.setState({ hover: false })
  }

  mousemove (event) {
    super.mousemove(...arguments)
    this.setState({ hover: event.target !== event.currentTarget })
    if (this.state.seeking && this.state.started) this.seek(event)
  }

  seek (event) {
    const progress = (event.pageX - this.bounds.x) / this.bounds.width
    this.video.currentTime = progress * this.video.duration
  }

  switchFullscreen (noTransition) {
    const item = this.el
    const bounds = this.el.getBoundingClientRect()
    const parent = item.parentNode
    const page = browser.findParent(this.el, '.page')
    this.clone = item.cloneNode(true)
    this.video.muted = false

    console.log(this.video, this.video.muted)
    this.clone.style.height = bounds.height + 'px'
    // item.style.margin = 0

    page.appendChild(item)
    parent.appendChild(this.clone)

    item.style.position = 'fixed'
    item.style.zIndex = 200

    if (noTransition) return super.switchFullscreen()

    return anime({
      targets: item,
      top: [bounds.top, 0],
      left: [bounds.left, 0],
      width: [bounds.width + 'px', page.offsetWidth],
      height: [bounds.height + 'px', resize.height()],
      duration: 600,
      easing: 'easeInOutSine',
      change: this.updateBounds
    })
      .finished
      .then(() => {
        item.style.width = ''
        item.style.height = ''
        this.clone.style.height = ''
      })
      .then(() => super.switchFullscreen())
  }

  exitFullscreen (noTransition) {
    if (this.talent) this.video.pause()
    this.video.muted = true
    super.exitFullscreen()

    if (noTransition) return

    const item = this.el
    const bounds = this.clone.getBoundingClientRect()
    const parent = this.clone.parentNode
    const page = browser.findParent(this.el, '.page')

    return anime({
      targets: item,
      top: [0, bounds.top],
      left: [0, bounds.left],
      // top: [0, this.talent ? item.offsetTop : bounds.top],
      // left: [0, this.talent ? item.offsetLeft : bounds.left],
      width: [page.offsetWidth, bounds.width + 'px'],
      height: [resize.height(), bounds.height + 'px'],
      duration: 600,
      easing: 'easeInOutSine',
      change: this.updateBounds
    })
      .finished.then(() => {
        item.setAttribute('style', '')
        parent.insertBefore(item, this.clone)
        parent.removeChild(this.clone)
        this.clone = null
      })
      .then(() => super.exitFullscreen())
  }

  updateBounds = throttle(() => this.computeBounds(), 1000 / 10)

  show () {
    if (!this.state.standalone) animateOut({ targets: [this.fullscreenButton] })
    return animateIn({ targets: [this.closeButton, this.details] })
  }

  hide () {
    if (!this.state.standalone) animateIn({ targets: [this.fullscreenButton] })
    const animation = animateOut({ targets: [this.closeButton, this.details] })

    if (this.state.standalone) return animation
    else return Promise.resolve()
  }

  standalone () {
    this.el.style.opacity = 0
    super.standalone()
  }

  metadata = () => {}

  play = () => this.setState({ playing: true, started: true })
  pause = () => this.setState({ playing: false })
  ended = () => this.setState({ playing: false, started: false })

  resize () {
    super.resize()
  }

  flush () {
    super.flush()
  }
}

export default Player
