import ProjectBlock from '../ProjectBlock'
import Carousel from './Carousel'

import anime from 'animejs'

import { animateIn, animateOut } from 'core/animations'

class Gallery extends ProjectBlock {
  current = 0

  constructor (el) {
    super(...arguments)

    this.counter = this.el.querySelector('.counter')
    this.counterSpan = this.counter.querySelector('span')
    this.container = this.el.querySelector('.images-wrapper')
    this.images = this.el.querySelectorAll('.image')
    this.total = this.images.length

    this.expandButton = this.el.querySelector('.expand-button')
    this.prevButton = this.el.querySelector('.prev-button')
    this.nextButton = this.el.querySelector('.next-button')

    this.preview = this.el.querySelector('.gallery-preview')
    this.full = this.el.querySelector('.gallery-full')
    this.panel = this.el.querySelector('.gallery-panel')

    this.mouseTarget = this.preview.querySelector('.expand-container')

    this.carousel = new Carousel(this.el.querySelector('.images'))
    this.carousel.emitter.on('update', this.onCarouselUpdate)

    this.state = Object.assign(this.state, {
      first: true,
      last: false
    })
    this.setState()
    this.injectDetails(el.querySelector('.gallery-full'))
    this.resize()
  }

  show () {
    this.carousel.enable()
    return animateIn({
      targets: [
        this.container,
        this.closeButton,
        this.details,
        [this.prevButton, this.nextButton],
        this.counter
      ]
    })
  }

  hide () {
    return animateOut({
      targets: [
        this.closeButton,
        this.details,
        [this.prevButton, this.nextButton],
        this.counter,
        this.container
      ]
    }).then(() => this.carousel.disable())
  }

  switchFullscreen (noTransition) {
    if (noTransition) {
      document.querySelector('.page').classList.add('popin')
      return super.switchFullscreen()
    }

    this.full.style.display = 'block'

    const item = this.preview
    const bounds = item.getBoundingClientRect()
    const parent = item.parentNode
    const clone = item.cloneNode(true)
    clone.style.height = bounds.height + 'px'
    // item.style.margin = 0

    this.panel.style.display = 'block'

    parent.appendChild(clone)

    item.style.position = 'fixed'
    item.style.top = bounds.top + 'px'
    item.style.left = bounds.left + 'px'
    item.style.width = bounds.width + 'px'
    item.style.height = bounds.height + 'px'
    item.style.zIndex = 250

    const timeline = anime.timeline()

    timeline.add({
      targets: this.panel,
      opacity: [0, 1],
      duration: 600,
      easing: 'linear'
    })

    timeline.add({
      targets: item,
      opacity: {
        value: [1, 0],
        delay: 200,
        duration: 400,
        easing: 'linear'
      },
      translateY: [0, -40],
      duration: 600,
      easing: 'easeInOutSine',
      complete: () => {
        document.querySelector('.page').classList.add('popin')
      }
    }, '-=200')

    timeline.add({
      targets: this.full,
      opacity: [0, 1],
      duration: 100,
      easing: 'linear'
    })

    return timeline.finished
      .then(() => {
        parent.removeChild(clone)
        item.setAttribute('style', '')
        this.panel.style.display = 'none'
      })
      .then(() => super.switchFullscreen())
  }

  exitFullscreen (noTransition) {
    if (noTransition) return super.exitFullscreen()

    return anime({
      targets: this.full,
      opacity: [1, 0],
      duration: 600,
      complete: () => {
        document.querySelector('.page').classList.remove('popin')
      },
      easing: 'linear'
    }).finished
      .then(() => this.full.setAttribute('style', ''))
      .then(() => super.exitFullscreen())
  }

  toggleEvents (add = true) {
    super.toggleEvents(...arguments)
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.prevButton[method]('click', this.prev)
    this.nextButton[method]('click', this.next)
  }

  toggleFullscreen () {
    this.mouseout()
    super.toggleFullscreen(...arguments)
  }

  tick () {
    if (this.inrtia.stopped) return
    const value = this.inrtia.update()
    this.expandButton.style.transform = `translate(${value.x}px, ${value.y}px)`
  }

  click (e) {
    super.click(...arguments)
    if (!this.state.fullscreen) this.toggleFullscreen()
  }

  mouseout () {
    if (this.state.fullscreen) return
    super.mouseout(...arguments)
  }

  mousemove (event) {
    if (this.state.fullscreen) return
    super.mousemove(...arguments)
  }

  prev = () => {
    if (this.state.first) return
    this.carousel.prev()
  }

  next = () => {
    if (this.state.last) return
    this.carousel.next()
  }

  onCarouselUpdate = (step) => {
    this.counterSpan.innerText = step + 1

    this.setState({
      first: step === 0,
      last: step === (this.total - 1)
    })
  }

  // updatePosition = () => {
  //   this.counterSpan.innerText = this.current + 1

  //   this.setState({
  //     first: this.current === 0,
  //     last: this.current === (this.total - 1)
  //   })

  //   anime({
  //     targets: this.container,
  //     translateX: `${-this.current * 100}%`,
  //     easing: 'easeOutSine',
  //     duration: 500
  //   })
  // }

  resize () {
    this.carousel && this.carousel.resize()
    super.resize()
  }

  flush () {
    this.carousel.flush()
    super.flush()
  }
}

export default Gallery
