import { animateOut } from 'core/animations'

export default function (callback) {
  this.header.hide()

  const cta = this.el.querySelector('.scroll-cta')
  const description = this.el.querySelector('.page__description-text')
  const showcase = this.el.querySelector('.showcase')
  const talents = this.el.querySelector('.talents')

  animateOut({
    targets: [[description, cta], talents, showcase]
  }).then(callback)
}
