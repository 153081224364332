import Inrtia from 'inrtia'
import raf from '@internet/raf'
import scroll from 'navigation/scroll'

import each from 'lodash/each'
import defer from 'lodash/defer'
import browser from 'helpers/browser'
import bowser from 'bowser'
import router from 'navigation/router'

import { animateIn, animateOut } from 'core/animations'
import { TinyEmitter } from 'tiny-emitter'

class ProjectBlock extends TinyEmitter {
  state = {
    idle: false,
    fullscreen: false,
    standalone: false
  }

  constructor (el, { talent = false } = {}) {
    super()
    this.el = el
    this.id = el.parentNode.getAttribute('data-id')
    this.projectName = el.parentNode.getAttribute('data-project')
    this.mouseTarget = this.el
    this.closeButton = el.querySelector('.close-button')
    this.talent = talent

    this.inrtia = new Inrtia({
      value: { x: 0, y: 0 },
      friction: 10,
      precisionStop: 0.1
    })

    this.resize()
    defer(() => this.toggleEvents(true))
  }

  injectDetails (container) {
    const details = browser
      .findParent(this.el, '.page')
      .querySelector('.project__detail-inner')
      .cloneNode(true)

    if (this.projectName)
      details.querySelector('.project__categories').innerText = this.projectName

    details.className = 'project__detail project__detail-block'
    container.appendChild(details)
    this.details = details
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'

    add &&
      browser.bindAll(
        ['close', 'click', 'mousemove', 'mouseout', 'toggleFullscreen', 'tick'],
        this
      )

    if (bowser.desktop) {
      this.mouseTarget[method]('mousemove', this.mousemove)
      this.mouseTarget[method]('mouseout', this.mouseout)
    }

    this.el[method]('click', this.click)
    this.closeButton && this.closeButton[method]('click', this.close)
    raf[add ? 'add' : 'remove'](this.tick)
  }

  close (event) {
    event && event.preventDefault()
    event && event.stopPropagation()
    if (this.state.standalone) {
      const last = router.lastPage()
      if (last) router.navigate(last)
      else router.navigate(event.currentTarget.getAttribute('href'))
    } else {
      this.toggleFullscreen(false)
    }
  }

  tick () {}

  toggleFullscreen (eventOrForce, noTransition = false) {
    let fullscreen

    if (typeof variable === typeof true) {
      fullscreen = eventOrForce
    } else {
      eventOrForce &&
        eventOrForce.stopPropagation &&
        eventOrForce.stopPropagation()
      fullscreen = !this.state.fullscreen
    }

    if (!this.state.standalone) {
      const hash = fullscreen ? '#' + this.id : ''
      if (window.history) {
        window.history.replaceState(
          false,
          false,
          window.location.pathname + hash
        )
      }
    }

    if (fullscreen) {
      Promise.resolve()
        .then(() => this.switchFullscreen(noTransition))
        .then(() => scroll.lock())
        .then(() => noTransition || this.show())
        .then(() => this.resize())
    } else {
      Promise.resolve()
        .then(() => noTransition || this.hide())
        .then(() => scroll.unlock())
        .then(() => this.exitFullscreen(noTransition))
        // .then(() => Promise.all([
        //   this.exitFullscreen(noTransition),
        //   noTransition || this.hide()
        // ]))
        .then(() => this.resize())
    }

    setTimeout(() => this.resize(), 50)
    return fullscreen
  }

  switchFullscreen () {
    this.setState({ fullscreen: true })
    this.emit('switchFullscreen')
  }

  exitFullscreen () {
    this.setState({ fullscreen: false })
    this.emit('exitFullscreen')
  }

  show () {
    const targets = [this.closeButton, this.details]
    each(targets, target => (target.style.opacity = 0))
    return animateIn({ targets })
  }

  hide () {
    const targets = [this.closeButton, this.details]
    return animateOut({ targets })
  }

  standalone () {
    this.setState({ standalone: true })
    this.toggleFullscreen(true, true)
  }

  click (event) {
    this.unidle()
  }

  mouseout () {
    this.inrtia.to({ x: 0.001, y: 0.001 })
    this.idle()
  }

  mousemove (event) {
    const x = event.pageX - (this.bounds.x + this.bounds.width / 2)
    const y = event.pageY - (this.bounds.y + this.bounds.height / 2)
    this.inrtia.to({ x, y })
    this.unidle()
  }

  idle = (force = true) => {
    if (this.state.idle) return
    this.setState({ idle: true })
    this._idleTimer = null
  }

  unidle = () => {
    this._idleTimer && clearTimeout(this._idleTimer)
    this._idleTimer = setTimeout(this.idle, 1500)

    if (!this.state.idle) return
    this.setState({ idle: false })
  }

  setState (newState = false) {
    if (newState) {
      each(newState, (state, key) => {
        if (this.state[key] === state) return
        this.state[key] = state
        this.el.classList.toggle(key, state)
      })
    } else {
      each(this.state, (state, key) => {
        this.el.classList.toggle(key, state)
      })
    }
  }

  computeBounds = () => {
    const bounds = this.mouseTarget.getBoundingClientRect()
    this.bounds = {
      x: bounds.left,
      y: bounds.top + scroll.scrollTop(),
      width: bounds.width,
      height: bounds.height
    }
  }

  resize () {
    this.computeBounds()
  }

  flush () {
    scroll.unlock()
    this.toggleEvents(false)
  }
}

export default ProjectBlock
