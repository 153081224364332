import { animateIn } from 'core/animations'
import each from 'lodash/each'

export default function (callback, previousPage) {
  if (previousPage !== 'Ecurie') this.header.show()

  this.resetAlpha()

  const slice = this.grid.columns.length === 1 ? 0 : 1
  const columns = this.columns().slice(slice)
  const description = this.el.querySelector('.page__description-text')
  const cta = this.el.querySelector('.scroll-cta')
  const targets = [[description, cta]]

  console.log(description)

  each(columns, column => each(column, item => (item.style.opacity = 0)))
  each(columns, column => targets.push(false, column))

  animateIn({
    targets,
    subDelay: 100,
    mainDelay: 200
  })
    .then(() => this.grid.start())
    .then(callback)
}
