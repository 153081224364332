import { animateOut } from 'core/animations'
import each from 'lodash/each'

export default function (callback, nextPage) {
  if (nextPage !== 'Ecurie') this.header.hide()
  else this.header.disableFilter()

  const columns = this.grid.columns
  const description = this.el.querySelector('.page__description-text')
  const cta = this.el.querySelector('.scroll-cta')
  const targets = [[description, cta]]

  each(columns, column => targets.push(column))
  animateOut({
    targets
  }).then(callback)
}
