import PageManager from 'navigation/EcuriePageManager'
import Page from 'navigation/EcuriePage'

import About from 'sections/about/About'
import Intro from 'sections/intro/Intro'
import Talent from 'sections/talent/Talent'
import Ecurie from 'sections/ecurie/Ecurie'
import Project from 'sections/project/Project'
import Cavalerie from 'sections/cavalerie/Cavalerie'

import scroll from 'navigation/scroll'

import resize from 'helpers/resize'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()
    this.createPageManager()

    resize.root = document.querySelector('.container')
    this.listenResize()
  }

  createPageManager () {
    const panel = this.el.querySelector('.panel')

    this.pageManager = new PageManager(document.querySelector('.container'), '.page', {
      'about': About,
      'lecurie': Ecurie,
      'lecurie/*': Project,
      'cavalerie': Cavalerie,
      'cavalerie/*': Talent,
      'project-categories/*': Ecurie,
      'talent-categories/*': Cavalerie,
      '': Intro,
      '*': Page
    }, { panel })
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager.resize()
  }
}

export default Main
