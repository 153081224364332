import EcuriePage from 'navigation/EcuriePage'
import EcurieGrid from './grid/EcurieGrid'
import scroll from 'navigation/scroll'

import hide from './animations/ecurie.hide'
import show from './animations/ecurie.show'
import showRight from './animations/ecurie.show.right'
import hideLeft from './animations/ecurie.hide.left'

import defer from 'lodash/defer'
import map from 'lodash/map'

class Ecurie extends EcuriePage {
  constructor (el) {
    super(...arguments)
    this.grid = new EcurieGrid(el)
  }

  columns = (limit = 5) => map(this.grid.columns, column => Array.from(column.children).slice(0, limit))

  show (callback, previousPage) {
    this.resize()

    const cb = () => {
      scroll.unlock()
      callback()
    }

    if (previousPage === 'Cavalerie') showRight.call(this, cb, previousPage)
    else show.call(this, cb, previousPage)
  }

  prepare (previousPage) {
    if (previousPage === 'Intro') scroll.lock()
    if (previousPage !== 'Ecurie') return super.prepare()
    this.grid.prepare()
  }

  hide (callback, nextPage) {
    defer(this._hide.bind(this, ...arguments))
  }

  _hide (callback, nextPage) {
    if (nextPage === 'Project' && this.grid.lastProjectClicked)
      this.hideWithItem(this.grid.lastProjectClicked, ...arguments)
    else if (nextPage === 'Cavalerie') hideLeft.call(this, ...arguments)
    else hide.call(this, ...arguments)
  }

  pageName () {
    return this.header.barVisible ? 'EcurieScrolled' : super.pageName()
  }

  resize () {
    super.resize()
    this.grid.resize()
    // this.resizeHeader('column-1')
  }

  flush () {
    super.flush()
    this.grid.flush()
    this.grid = null
  }
}

Ecurie.pageName = 'Ecurie'

export default Ecurie
