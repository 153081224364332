import browser from 'helpers/browser'
import Emitter from 'tiny-emitter'
import math from 'helpers/math'
import bowser from 'bowser'

import Inrtia from 'inrtia'
import raf from '@internet/raf'

import now from 'lodash/now'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import toPairs from 'lodash/toPairs'

const SPEED = 1.2

class Carousel {
  constructor (el) {
    this.el = el
    this.lis = Array.from(this.el.querySelectorAll('.image'))

    this.resize()
    this.emitter = new Emitter()
    this.inrtia = new Inrtia({
      value: this.minStep,
      friction: 10
    })

    this.selectStep(0)
    this.updatePosition(true)
    this.toggleEvents(true)
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    document.body[method](bowser.desktop ? 'mousedown' : 'touchstart', this.mousedown)
    document.body[method](bowser.desktop ? 'mouseup' : 'touchend', this.mouseup)
    document.body[method](bowser.desktop ? 'mousemove' : 'touchmove', this.mousemove)
    raf[add ? 'add' : 'remove'](this.updatePosition)
  }

  enable () {
    this._enable = true
  }

  disable () {
    this._enable = false
  }

  prev = () => {
    const step = math.wrap(this.currentStep - 1, 0, this.lis.length)
    this.inrtia.to(this.steps[step])
    this.selectStep(step)
  }

  next = () => {
    const step = math.wrap(this.currentStep + 1, 0, this.lis.length)
    this.inrtia.to(this.steps[step])
    this.selectStep(step)
  }

  mousedown = (event) => {
    if (this._enable === false) return

    event = browser.mouseEvent(event)
    this.inrtia.stop()

    this.firstFrame = {
      time: now(),
      x: event.pageX,
      value: this.inrtia.value
    }

    this.el.classList.add('grabbing')

    this.inrtia.stop()
  }

  mouseup = (event) => {
    if (this._enable === false || !this.firstFrame) return
    event = browser.mouseEvent(event)

    const distance = event.pageX - this.firstFrame.x

    const value = this.firstFrame.value + (distance * SPEED)
    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))
    const newValue = this.findStep(clampedValue, true)

    this.inrtia.to(newValue)
    this.firstFrame = false
    this.el.classList.remove('grabbing')
  }

  reset () {
    if (!this.inrtia) return
    const newValue = this.steps[this.currentStep]
    if (newValue === this.inrtia.targetValue) return
    this.inrtia.to(newValue)
  }

  mousemove = (event) => {
    if (this._enable === false || !this.firstFrame) return
    event = browser.mouseEvent(event)

    const distance = event.pageX - this.firstFrame.x
    const value = this.firstFrame.value + (distance * SPEED)
    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))

    // this.inrtia.value = clampedValue
    // this.inrtia.targetValue = clampedValue
    // this.updatePosition(true)

    this.inrtia.to(clampedValue)
  }

  findStep (value, select = false) {
    const sorted = sortBy(toPairs(this.steps), (step, i) => Math.abs(value - step[1]))
    const index = parseInt(sorted[0][0])

    if (select) this.selectStep(index)
    return this.steps[index]
  }

  updatePosition = (force) => {
    if (this._enable === false) return
    if (this.inrtia.stopped && force !== true) return
    const value = this.inrtia.update()
    this.el.style.transform = `translateX(${value}px)`
  }

  selectStep (step) {
    this.currentStep = step
    this.emitter.emit('update', step)
  }

  resize () {
    this.steps = reduce(this.lis, (memo, li, i) => {
      let w = i === 0 ? 0 : -li.offsetWidth
      if (i > 0) w += memo[i - 1]
      memo.push(w)
      return memo
    }, [])

    this.minStep = this.steps[0]
    this.maxStep = this.steps[this.steps.length - 1]

    const lastAttribute = this.el.getAttribute('style', '')
    if (this._enable === false) this.el.setAttribute('style', '')
    else if (lastAttribute === '') this.reset()
  }

  flush () {
    this.toggleEvents(false)
  }
}

export default Carousel
